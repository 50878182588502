import axios from "axios";
import constants from "../utils/constants";
import CryptoJS from "crypto-js";

let foodTruckBaseUrl = "https://foodtruck.onerare.io";
let apiUrl = "https://v2.onerare.io/api";
let newApiUrl = "https://api.gobbl.io/api";
// let newApiUrl = "https://testapi.gobbl.io/api";
let testUrl = "https://game.onerare.io/api";

const getCipherText = (inputBodyData) => {
  let secretKey = CryptoJS.enc.Utf8.parse(constants.CIPHER_KEY);
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(inputBodyData),
    secretKey,
    {
      mode: CryptoJS.mode.ECB,
    }
  ).toString();

  return { data: ciphertext.toString() };
};

// Pool Orare Balance - Admin Wallet
export const getDBOrareBalance = async () => {
  let url = `${apiUrl}/stats/getTotalDBBalance`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Stats for analytics of total game
export const getDBAdminStats = async () => {
  let url = `${apiUrl}/stats/getStats`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return [];
  }
};

// farmers market total sales
export const getTotalMarketSalesVolume = async () => {
  let url = `${apiUrl}/marketplace/getTotalSales`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// farmers market sales daily transaction details
export const getMarketSalesDailyVolume = async (startDate, endData) => {
  let data = {
    startDate: startDate,
    endDate: endData,
  };
  let url = `${apiUrl}/stats/getDailySales`;
  let response = await axios
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};
// Most active user
export const getMostActiveUsersAPI = async () => {
  let data = {
    startDate: null,
    endDate: null,
  };
  let url = `https://v2.onerare.io/api/stats/getMostActiveUsers`;
  let response = await axios
    .get(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  console.log("response", response);
  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// FTWars Wins API
export const getFTWarsWinsAPI = async () => {
  let url = `${apiUrl}/stats/getMostFTWWins`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get SignUps Data from Server
export const getSignupsDataAPI = async (startDate, endData) => {
  let data = {
    startDate: null,
    endDate: null,
  };
  let url = `${apiUrl}/stats/getSignUps`;
  let response = await axios
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get Claim of Ingredients Data from Server
export const getClaimIngreDataAPI = async (startDate, endData) => {
  let data = {
    startDate: startDate,
    endDate: endData,
  };
  let url = `${apiUrl}/stats/getClaims`;
  let response = await axios
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// GET User foodtruckwars balance
export const getUserFoodTruckWarsBalance = async (address) => {
  let url = `${foodTruckBaseUrl}/balanceOf`;
  let response = await axios
    .post(url, { userAddress: address })
    .then((res) => res.data);

  if (response) {
    return parseFloat(response.balance);
  } else {
    return 0;
  }
};

// All users balances
export const getDBUserOrareBalances = async () => {
  let url = `${apiUrl}/stats/getUsersBalance`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
  if (response && !response.error) {
    return response.result;
  } else {
    return [];
  }
};
// Check if user is eligible for claiming airdrop
export const getDBOrareDepositAmount = async (address) => {
  let userAddress = "0x9b4b8fac99695dd40a616a47290f2fb3b5ae53cd";
  let url = `${foodTruckBaseUrl}/getUserWalletBalance/${userAddress}`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

export const getUserDepositWithdraw = async (address) => {
  let url = `${apiUrl}/user/getDepositWithdrawHistory/${address}`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

export const getUserIngredientIds = async (address) => {
  let url = `${apiUrl}/user/getUserIngredientIds/${address}`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

// get withdrawal requests
export const getWithdrawRequests = async () => {
  let url = `${apiUrl}/user/getWithdrawRequests`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

// approve withdrawal requests
export const processWithdrawOrare = async (withdrawId, adminAddress) => {
  let url = `${apiUrl}/user/processWithdrawOrare`;
  const data = {
    withdrawId,
    adminAddress,
  };
  let encryptedData = await getCipherText(data);
  let response = await axios
    .post(url, encryptedData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

// get all coupons
export const getAllCoupons = async () => {
  let url = `${apiUrl}/stats/getAllCoupons`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

// get coupon details
export const getCouponUsers = async (code) => {
  let url = `${apiUrl}/stats/getCouponUsers/${code}`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

// Get users ton data
export const getAnalyticsDataTon = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataTon`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get users tasks data
export const getAnalyticsDataTasks = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataTasks`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get users referral data
export const getAnalyticsDataReferral = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataReferral`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get no. of wallet users
export const getAnalyticsDataWallet = async () => {
  let url = `${newApiUrl}/user/getAnalyticsDataWallet`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get user data by user id
export const getAnalyticsDataByTelegramId = async (userId) => {
  let url = `${newApiUrl}/user/getAnalyticsDataByTelegramId?telegramId=${userId}`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};

// Get Daily Claims And Referrals Data
export const getDailyClaimsAndReferralsData = async () => {
  let url = `${newApiUrl}/restaurant/getDailyClaims`;
  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (response && !response.error) {
    return response.result;
  } else {
    return 0;
  }
};
